/* eslint-disable no-underscore-dangle */
import React, { useMemo, useState, useEffect } from "react";
import { Col, Form, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components/macro";
// import Moment from "react-moment";
import { useFormik } from "formik";

// Internal dependencies
import Layout from "components/Layout";
import PageTitle from "components/PageTitle";
import ModalFormLabel from "components/ModalFormLabel";
import Input from "components/Input";
import "styles/tab.scss";
import bannerImg from "assets/images/bannerImg.png";
import Button from "components/Button";
import ModalContainer from "components/ModalContainer";
import Pagination from "components/Table/Pagination";
import Table from "components/Table";
import { handleTableChange } from "utils";
import { testBanksData } from "utils/data";
import settingService from "services/settings.service";
import {  useDispatch, useSelector } from "react-redux";
import { BankAccountSchema, profileSchema } from "schema/schema";
import { fetchUserAction } from "redux/actions/authActions";
import authService from "services/auth.service";
import { toast } from "react-toastify";

const Settings = () => 
	// console.log('settings');
	 (
		<>
			<Layout>
				<div className="mb-4">
					<PageTitle className="mb-4" text="Settings" />
				</div>

				<div>
					<div className="card border-0">
						<div className="card-body">
							<Tabs defaultActiveKey="profile" id="settings">
								<Tab tabClassName="ctab" eventKey="profile" title="Profile" >
									<ProfileTab />
								</Tab>
								<Tab
									tabClassName="ctab"
									eventKey="bank_accounts"
									title="Bank Accounts"
								>
									<BankAccounts />
								</Tab>
							</Tabs>
						</div>
					</div>
				</div>
			</Layout>
		</>
	)
;
const ProfileTab = () => { 
	const state = useSelector(s=>s.auth.user);
	const [error, setError] = useState(null);
	const [image, setImage] = useState(bannerImg);
	const [isuploading, setIsUploading] = useState(false);
	const dispatch = useDispatch();
	useEffect(()=>{
		setImage(state.logoUrl ? state.logoUrl : bannerImg)
	}, [state])

	// console.log(state);
	const postFormData = async (da, setSubmitting) => {
		try {
			const re = await settingService.updateProfile(da);
			setSubmitting(false);
			dispatch(fetchUserAction(re.data))
		} catch (err) {
			setError(err.response.data.message);
			setSubmitting(false);
		}
	};
	const {
		handleBlur,
		handleChange,
		handleSubmit,
		values,
		errors,
		touched,
		isSubmitting,
		setSubmitting,
		isValid,
		// setFieldValue
	} = useFormik({
		initialValues: {
			companyName: state.companyName ? state.companyName : "",
			phone: state.phone ? state.phone : "",
			website: state.website ? state.website :"",
			address1: state.address1 ? state.address1 :"",
			address2: state.address2 ? state.address2 :"",
			address3: state.address3 ? state.address3 :"",
			logo: state.logoId ? state.logoId :""
		},
		validationSchema:profileSchema,
		onSubmit: (valueData) => postFormData(valueData, setSubmitting),
	});
	
	const uploadLogo = async (file) => {
		setIsUploading(true)
		settingService.uploadFile(file).then((re)=>{
			// console.log(re.data);
			setIsUploading(false);
			const v = values;
			v.logo = re.data._id
			postFormData(values, setSubmitting)
		}).catch(e=>{
			// console.log(e);
			setError(e.message)
			setIsUploading(false);
		});
	}

	const FileUpload = async (e) => {
		const file = e.target.files[0]
		// console.log(file);
		if(file.size > (3e+6)){
			setError('Image Should not be more than 3MB!!')
			// return null;
		} else if(!isValid){
			setError('Complete Profile Update to Upload.');
		}else{
			uploadLogo(file)	
		}
	}
	
	const resedVerificationEmail = async () => {
		try {
			const da = await authService.sendEmailVerify(state.email);
			setSubmitting(false);
			toast.success(da.message);
			// postFormData(values, setSubmitting);
		} catch (err) {
			toast.error(err.response.data.message);
			setSubmitting(false);
		}
	}
	return (
		<div className="row">
				<div className="col-12 col-lg-5 order-lg-2">
					<div className="py-5 pr-5">
						<Banner className="d-flex flex-column align-items-center text-center">
							<h5 className="title mb-3">Business Logo</h5>
							<p className="info">
								This logo will appear on all invoices sent to your
								customers. Recommended size is 200px x 200px.
							</p>

							<div className="mb-4">
								{!isuploading && !isSubmitting  ? <img
									width="60"
									className="img-fluid"
									src={image}
									alt="Logo"
								/>: <Spinner animation="grow"/>}
							</div>

							<button type="button" className="btn banner-btn mb-2" onClick={()=>{
								document.getElementById('fileInput').click()
							}} disabled={isuploading || isSubmitting }>
								<input type="file" style={{display: 'none'}} id="fileInput" accept="image/*" onChange={(e)=>FileUpload(e)} />
								Change
							</button>

							<p className="info mb-0">Max size: 3mb</p>
						</Banner>
						{state.emailToken && <div className="row mt-5 mx-auto text-center justify-content-center">
							<p className="col-12">Email Verification is required Please click this button to get verification link</p>
							<Button
								className="px-5"
								variant="green"
								type="submit"
								text={!isSubmitting ? "Resed Email Verification": <Spinner animation='grow' />}
								disabled={isSubmitting || !isValid}
								onClick={resedVerificationEmail}
							/>
						</div>}
					</div>
				</div>
				<div className="col-12 col-lg-7">
					<div className="p-5">
						<Form onSubmit={handleSubmit}>
						{error && (
							<div className="text-danger text-center">
								<p>*{error}</p>
							</div>
						)}
							<Form.Group
								as={Row}
								className="align-items-center mb-5"
							>
								<ModalFormLabel column sm="3">
									Default currency
								</ModalFormLabel>
								<Col sm="9">
									<Input
										type="select2"
										name="currency"
										readOnly
									>
										<option>NGN</option>
									</Input>
								</Col>
							</Form.Group>

							<Form.Group
								as={Row}
								className="align-items-center mb-5"
							>
								<ModalFormLabel column sm="3">
									Business name
								</ModalFormLabel>
								<Col sm="9">
									<Input type="text2" name="company"
										onChange={handleChange}
										value={values.companyName}
										onBlur={handleBlur} />
									{errors.companyName && touched.companyName && (
										<span className="error">{errors.companyName}</span>
									)}
								</Col>
							</Form.Group>

							<Form.Group
								as={Row}
								className="align-items-center mb-5"
							>
								<ModalFormLabel column sm="3">
									Phone number
								</ModalFormLabel>
								<Col sm="9">
									<Input type="text2" name="phone"
										onChange={handleChange}
										value={values.phone}
										onBlur={handleBlur}
										 />
									{errors.phone && touched.phone && (
										<span className="error">{errors.phone}</span>
									)}
								</Col>
							</Form.Group>

							<Form.Group
								as={Row}
								className="align-items-center mb-5"
							>
								<ModalFormLabel column sm="3">
									Website
								</ModalFormLabel>

								<Col sm="9">
									<Input type="text2" name="website"
										onChange={handleChange}
										value={values.website}
										onBlur={handleBlur} />
									{errors.website && touched.website && (
										<span className="error">{errors.website}</span>
									)}
								</Col>
							</Form.Group>

							<Form.Group as={Row} className=" mb-5">
								<ModalFormLabel column sm="3">
									Office Address
								</ModalFormLabel>

								<Col sm="9">
									<Input
										type="text2"
										name="address1"
										onChange={handleChange}
										value={values.address1}
										onBlur={handleBlur}
										className=" mb-3"
									/>
									<Input
										type="text2"
										name="address2"
										onChange={handleChange}
										value={values.address2}
										onBlur={handleBlur}
										className=" mb-3"
										placeholder="Address 1"
									/>
									<Input
										type="text2"
										name="address3"
										onChange={handleChange}
										value={values.address3}
										onBlur={handleBlur}
										placeholder="Address 2"
									/>
									{errors.address1 && touched.address1 && (
										<span className="error">{errors.address1}</span>
									)}
									{errors.address2 && touched.address2 && (
										<span className="error">{errors.address2}</span>
									)}
									{errors.address3 && touched.address3 && (
										<span className="error">{errors.address3}</span>
									)}
								</Col>
							</Form.Group>

							<Form.Group as={Row} className="align-items-center">
								<ModalFormLabel column sm="3">
									Email
								</ModalFormLabel>

								<Col sm="9">
									<Input type="text2" name="firstName" value={state.email} readOnly/>
								</Col>
							</Form.Group>

							<div className="d-flex justify-content-end mt-5">
								<Button
									className="px-5"
									variant="green"
									type="submit"
									text={!isSubmitting ? "Update": <Spinner animation='grow' />}
									disabled={isSubmitting || !isValid}
									onClick={handleSubmit}
								/>
							</div>
						</Form>
					</div>
				</div>
		</div>
	)
};

const BankAccounts = () => {
	const [banks, setBanks] = useState(null);
	const [showNewBankModal, setShowNewBankModal] = useState(false);
	const [showDelModal, setShowDelModal] = useState(false);

	const handleNewBankModalHide = () => setShowNewBankModal(false);
	const handleNewBankModalShow = () => setShowNewBankModal(true);

	const handleDelModalHide = () => setShowDelModal(false);
	const handleDelModalShow = () => setShowDelModal(true);
	const [error, setError] = useState(null);

	const [state, setState] = useState({
		page: 1,
		data: [],
	});

	const columns = useMemo(
		() => [
			{
				dataField: "bankName",
				text: "Bank Name",
				formatter: (cell, { bankName }) => (
					<p className="mb-0" css="color: #3ab75d;">
						{bankName}
					</p>
				),
			},
			{
				dataField: "accountName",
				text: "Account Name",
			},
			{
				dataField: "accountNumber",
				text: "Account Number",
			},
			{
				dataField: "currency",
				text: "Currency",
				formatter: (cell, { currency }) => (
					<p className="mb-0">
						{currency || 'NGN'}
					</p>
				),
			},
			{
				dataField: "actions",
				text: "",
				align: "right",
				headerClasses: "bs-table-col-action",
				formatter: () => (
					<div className="d-flex ml-auto" css="width:200px">
						<Button.TableDelBtn
							text="Delete Account"
							onClick={handleDelModalShow}
						/>
					</div>
				),
			},
		],
		[]
	);
	const getData = async () => {
		const all = await settingService.fetchAccounts();
		// console.log(all);
		setState({
			page: all.data.page,
			data: all.data.banks,
		});
	};
	const getBankList = async() => {
		const all = await settingService.fetchBankList();
		// console.log(all);
		setBanks(all.data);
	}

	useEffect(() => {
		getData();
		getBankList();
	}, []);
	const getbank = (code) => banks.filter(b=>b.id===code)[0]

	const postFormData = async (da, setSubmitting) => {
		setError(null);
		const v = da;
		// console.log(v)
		delete v.length;
		v.bankName = getbank(v.bankCode).name;
		try {
			// const val = v;
			await settingService.addAccount(v);
			setSubmitting(false);
			getData();
			handleNewBankModalHide();
		} catch (err) {
			setError(err.response.data.message);
			setSubmitting(false);
		}
	};
	const {
		handleBlur,
		handleChange,
		handleSubmit,
		values,
		errors,
		touched,
		isSubmitting,
		setSubmitting,
		isValid,
		setFieldValue
	} = useFormik({
		initialValues: {
			// country: "",
			length:state.data.length,
			bankCode: "",
			accountName: "",
			accountNumber: "",
			// currency: "NGN",
			primary: false,
			bvn: "",
		},
		validationSchema:BankAccountSchema,
		onSubmit: (valueData) => postFormData(valueData, setSubmitting),
	});

	const validateAccountNumber = async (val)=>{
		setFieldValue('accountName', '');
		setFieldValue('accountNumber', val)
		// console.log(val, values.bankCode);
		if(val.length === 10 && values.bankCode){
			// const accountData = await settingService.validateAccount(values.bankCode,values.accountNumber);
			// console.log(accountData);
			setFieldValue('accountName', "Sample Account Name");
		}
	}

	return (
		<>
			{/* Add new bank modal */}
			<ModalContainer
				modalTitle="Add new bank account"
				show={showNewBankModal}
				onHide={handleNewBankModalHide}
			>
				<Form onSubmit={handleSubmit}>
						{error && (
							<div className="text-danger text-center">
								<p>*{error}</p>
							</div>
						)}
					<Form.Group>
						<ModalFormLabel>Country</ModalFormLabel>
						<Input
							type="select2"
							name="country"
							placeholder="Select your country"
							// onChange={handleChange}
							value='Nigeria'
							// onBlur={handleBlur}
							readOnly
						>
							<option>Nigeria</option>
						</Input>
					</Form.Group>

					<Form.Group>
						<ModalFormLabel>Currency</ModalFormLabel>
						<Input
							type="text2"
							name="currency"
							placeholder="Select your currency"
							// onChange={handleChange}
							value='NGN'
							// onBlur={handleBlur}
							readOnly
						/>
					</Form.Group>

					<Form.Group>
						<ModalFormLabel>Bank Name</ModalFormLabel>
						<Input
							type="select2"
							name="bankCode"
							placeholder="Select your bank"
							onChange={handleChange}
							value={values.bankCode}
							onBlur={handleBlur}>
								{banks && banks.map((bank) =><option value={bank.id}>{bank.name}</option>)}
							</Input>
							{errors.bankCode && touched.bankCode && (
								<span className="error">{errors.bankCode}</span>
							)}
					</Form.Group>

					<Form.Group className="mb-4">
						<ModalFormLabel>Account Number</ModalFormLabel>
						<Input
							type="text2"
							name="accountNumber"
							placeholder="Enter account number"
							onChange={(v)=>validateAccountNumber(v.target.value)}
							value={values.accountNumber}
							onBlur={handleBlur}
						/>
						<p className="text-small">{values.accountName}</p>

						{errors.accountNumber && touched.accountNumber && (
							<span className="error">{errors.accountNumber}</span>
						)}
						{errors.accountName && touched.accountName && (
							<span className="error">{errors.accountName}</span>
						)}
					</Form.Group>

					{state.data.length === 0 && <Form.Group className="mb-4">
						<ModalFormLabel>BVN</ModalFormLabel>
						<Input
							type="text2"
							name="bvn"
							placeholder="Enter bvn Number"
							onChange={handleChange}
							value={values.bvnNmber}
							onBlur={handleBlur}
						/>
						{errors.bvn && touched.bvn && (
							<span className="error">{errors.bvn}</span>
						)}
					</Form.Group>}

					<Form.Check
						className="mb-5"
						type="checkbox"
						label="Make this account your primary account"
						onChange={handleChange}
						value={values.primary}
						onBlur={handleBlur}
					/>

					<div className="d-flex justify-content-end">
						<Button
							className="px-5"
							variant="green"
							type="submit"
							text={!isSubmitting ? "Done": <Spinner animation='grow' />}
							disabled={isSubmitting || !isValid}
							onClick={handleSubmit}
						/>
					</div>
				</Form>
			</ModalContainer>

			{/* Cancel Plan Modal */}
			<ModalContainer
				modalTitle="Delete Account"
				show={showDelModal}
				onHide={handleDelModalHide}
			>
				<p>Please confirm that you want to delete this account.</p>
				<div className="d-flex">
					<Button
						variant="outline"
						className="px-4 mr-4"
						text="Back"
						onClick={handleDelModalHide}
					/>
					<Button variant="red" className="px-4" text="Yes, delete account" />
				</div>
			</ModalContainer>


			<div>
				<div className="d-flex justify-content-between align-items-center px-4 py-3">
					<p className="mb-0">
						Enter the bank account you would to send your payouts to
					</p>
					<Button
						variant="green"
						text="Add account"
						onClick={handleNewBankModalShow}
					/>
				</div>

				<div>
					<Pagination size={testBanksData.length}>
						<Table
							keyField="customer"
							data={state.data}
							page={state.page}
							columns={columns}
							onTableChange={(type, { page, sizePerPage }) =>
								handleTableChange(
									type,
									{ page, sizePerPage },
									testBanksData,
									setState
								)
							}
							noDataIndication={() => (
								<p className="text-center">
									You haven&apos;t added any member yet.
								</p>
							)}
						/>
					</Pagination>
				</div>
			</div>
		</>
	)								
}

const Banner = styled.div`
	background: #ffffff;
	border: 0.5px solid #c4c4c4;
	border-radius: 6px;
	padding: 64px 60px;

	.title {
		font-weight: 700;
		font-size: 1.125rem;
		line-height: 21px;
		letter-spacing: -0.01em;
	}

	.info {
		font-size: 0.75rem;
		line-height: 14px;
		text-align: center;
		color: #c4c4c4;
	}

	.banner-btn {
		border: 0.5px dashed #3ab75d;
		border-radius: 2px;
		font-size: 0.875rem;
		line-height: 17px;
		width: 180px;
	}
`;

export default Settings;
