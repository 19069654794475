import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Internal dependencies
import store from "redux/store";
import routes from "routes";
import PrivateRoute from "components/PrivateRoute";
import Head from "head";

const App = () => (
	<Provider store={store().store}>
		<PersistGate loading={null} persistor={store().persistor}>
			<Head />
			<BrowserRouter>
				<Switch>
					{routes.map(({ path, name, Component, isProtected }) => {
						if (isProtected) {
							return (
								<PrivateRoute
									exact
									key={name}
									path={path}
									component={Component}
								/>
							);
						}

						return <Route exact key={name} path={path} component={Component} />;
					})}
					<Redirect from="/" to="/auth/sign-in" />
				</Switch>
			</BrowserRouter>
			<ToastContainer />
		</PersistGate>
	</Provider>
);

export default App;
