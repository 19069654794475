import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useQuery } from "utils";
import authService from "services/auth.service";
import { useHistory } from "react-router";

const EmailVerify =() => {
    const [ isVerifying, setIsVerifying ] = useState(false);
	const history = useHistory();
	const verificationToken = useQuery().get("token");
    const verifyEmail = async () =>{
		try {
            const da = await authService.verifyEmail(verificationToken);
			setIsVerifying(false);
            history.push("/auth/sign-in")
            // console.log(da);
            toast.success(da.message)
		} catch (err) {
			// console.log(err);
            toast.error(err.response.data.message)
			setIsVerifying(false);
		}
    }

    // perform  email verification if token persent
	useEffect(() => {
		if (verificationToken) {
			verifyEmail()
		}
	}, []);

	return (
		<div className="auth-wrapper">
            {isVerifying ? <p>Email Verification in Progress</p> : <p>Email verification required</p>}
        </div>
	);
};

export default EmailVerify;
