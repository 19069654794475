/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Internal dependencies
import Layout from "components/Layout";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import Button from "components/Button";
import ModalContainer from "components/ModalContainer";
import Input from "components/Input";
import ModalFormLabel from "components/ModalFormLabel";
import { Form, Spinner } from "react-bootstrap";
// import Moment from "react-moment";
import { useFormik } from "formik";
import { testPayoutData } from "utils/data";
import { handleTableChange } from "utils";
import PageTitle from "components/PageTitle";
import payoutService from "services/payout.service";
import settingService from "services/settings.service";
import { payOutSchema } from "schema/schema";

const columns = [
	{
		dataField: "reference",
		text: "Reference",
		formatter: (cell, { reference }) => (
			<StyledLink to="/#">{reference}</StyledLink>
		),
	},
	{
		dataField: "status",
		text: "Status",
	},
	{
		dataField: "paid-on",
		text: "Paid on",
	},
	{
		dataField: "amount",
		text: "Amount",
	},
];

const Payouts = () => {
	const [showModal, setShowModal] = useState(false);
	const [total, setTotal] = useState(0);
	const [error, setError] = useState(null);
	const [banks, setBanks] = useState([]);
	const handleModalShow = () => setShowModal(true);
	const handleModalHide = () => setShowModal(false);
	const [state, setState] = useState({
		page: 1,
		data: [],
	});
	const getData = async () => {
		const all = await payoutService.fetch();
		// console.log(all);
		setState({
			page: all.data.page,
			data: all.data.payouts,
		});
		setTotal(all.data.totalRecieved)
		const nl = await settingService.fetchAccounts();
		setBanks(nl.data.banks, banks)
		const walletData = await settingService.walletData();
		// console.log(walletData.data.balance);
		setTotal(walletData.data.balance);
	};

	const postFormData = async (v, setSubmitting) => {
		setError(null);
		try {
			// const val = v;
			await payoutService.create(v);
			setSubmitting(false);
			getData();
			setShowModal(false);

			// history.push("/dashboard");
		} catch (err) {
			// console.log(err);
			setError(err.response.data.message);
			setSubmitting(false);
		}
	};


	useEffect(() => {
		getData();
	}, []);
	const {
		handleBlur,
		handleChange,
		handleSubmit,
		values,
		errors, touched,
		isSubmitting,
		setSubmitting,
		isValid,
	} = useFormik({
		initialValues: {
			amount: "",
			account:null
		},
		validationSchema: payOutSchema,
		onSubmit: (valueData) => postFormData(valueData, setSubmitting),
	});
	return (
		<Layout>
		<ModalContainer
			modalTitle="Create a new paylink"
			show={showModal}
			onHide={handleModalHide}
		>
			<Form onSubmit={handleSubmit}>
				{error && (
					<div className="text-danger text-center">
						<p>*{error}</p>
					</div>
				)}
				<Form.Group>
					<ModalFormLabel>Amount</ModalFormLabel>
					<Input
						type="text2"
						name="amount"
						value={values.amount}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					{errors.amount && touched.amount && (
						<span className="error">{errors.amount}</span>
					)}
				</Form.Group>

				<Form.Group>
					<ModalFormLabel>Account</ModalFormLabel>
					<Input
						type="select2"
						name="account"
						placeholder="Select your account"
						onChange={handleChange}
						value={values.account}
						onBlur={handleBlur}>
							<option value={null} selected>Select Account</option>
							{banks && banks.map((bank) =><option value={bank._id}>{bank.bankName} ({bank.accountNumber})</option>)}
						</Input>
						{errors.account && touched.account && (
							<span className="error">{errors.account}</span>
						)}
				</Form.Group>

				<Button
					variant="green"
					className="px-4"
					text={
						isSubmitting ? <Spinner animation="grow" /> : "Create Paylink"
					}
					onClick={handleSubmit}
					disabled={isSubmitting || !isValid}
				/>
			</Form>
		</ModalContainer>

				<div className="d-flex align-items-center justify-content-between mb-4">
					<div className="mb-4">
						<PageTitle className='mb-4' text="Payouts" />
						<PageTitle.Sub text="This is where you'll be able to see the money we have paid to you." />
					</div>
					<div className="d-flex">
						<p className="mr-4 text-center my-auto">Total amount received</p>
						<Button variant="outline" text={total} className="mr-2 px-4" disabled/>
						<Button variant="green" className="px-4" text="CashOut" onClick={handleModalShow} />
					</div>
				</div>

			<div>
				<Pagination size={testPayoutData.length}>
					<Table
						keyField="customer"
						data={state.data}
						page={state.page}
						columns={columns}
						onTableChange={(type, { page, sizePerPage }) =>
							handleTableChange(
								type,
								{ page, sizePerPage },
								testPayoutData,
								setState
							)
						}
						noDataIndication={() => (
							<p className="text-center">
								You haven&apos;t added any payouts yet 
							</p>
						)}
					/>
				</Pagination>
			</div>
		</Layout>
	);
};

const StyledLink = styled(Link)`
	color: #3ab75d;

	:hover {
		color: #3ab75d;
	}
`;

export default Payouts;
