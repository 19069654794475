import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Form, Spinner  } from "react-bootstrap";
import { useFormik } from 'formik';
// import axiosClient from "axiosClient";
import ReactFlagsSelect from "react-flags-select";

// Internal dependencies
import Input from "components/Input";
import Button from "components/Button";
import "./auth.scss";
import { useDispatch} from 'react-redux';
// import endpoints from "utils/endpoints";
import { signInAction } from "redux/actions/authActions";
import { registerSchema } from "schema/schema";
import authService from "services/auth.service";

const Login = () => {
	// const [phone, setPhone] = useState('');
	const history = useHistory();
	const dispatch = useDispatch();
	const [error, setError] = useState(null);
	const postFormData = async (v, setSubmitting) => {
		setError(null);
		try{
			const response = await authService.register(v);
			dispatch(signInAction(response.data));
			setSubmitting(false);
			history.push("/dashboard");
		}catch(err){
			setError(err.response.data.message);
			setSubmitting(false);
		}
	}
	const {
		handleBlur, handleChange, handleSubmit, values, isSubmitting, setSubmitting, setFieldValue, errors, touched
	} = useFormik({
	initialValues: {
		name:'',
		email:'',
		company: '',
		country:'',
		password: '',
	},
	validationSchema: registerSchema,
	onSubmit: (valueData) =>
		 postFormData(valueData, setSubmitting)
	});

	return (
		<div className="auth-wrapper">
			<div className="auth-content">
				<form className="card auth-card" onSubmit={handleSubmit}>
					<h3 className="auth-card-title">Join Collect</h3>
					{error && (
						<div className="text-danger text-center">
							<p>*{error}</p>
						</div>
					)}
					<div>
						<Form.Group>
							<Input
								type="text"
								placeholder="Your Name"
								name="name"
								onChange={handleChange}
								value={values.name}
								onBlur={handleBlur}
							/>
							{errors.name && touched.name && (
								<span className="error">{errors.name}</span>
							)}
						</Form.Group>
						<Form.Group>
							<Input
								type="text"
								placeholder="Company Name"
								name="company"
								onChange={handleChange}
								value={values.company}
								onBlur={handleBlur}
							/>
							{errors.company && touched.company && (
								<span className="error">{errors.company}</span>
							)}
						</Form.Group>
						<Form.Group>
							<Input
								type="text"
								placeholder="Email Address"
								name="email"
								onChange={handleChange}
								value={values.email}
								onBlur={handleBlur}
							/>
							{errors.email && touched.email && (
								<span className="error">{errors.email}</span>
							)}
						</Form.Group>

						<Form.Group className="">
							{/* <PhoneInput
								inputClass="auth-phone"
								buttonClass="auth-phone_btn"
								name="phone"
								country="ng"
								value={values.phone}
								onChange={(phoneValue) => setFieldValue("phone", phoneValue)}
							/> */}
							<ReactFlagsSelect
								searchable
								countries={["NG"]} 
								selectButtonClassName="auth-phone"
								selected={values.country}
								onSelect={(code) => setFieldValue('country',code)}
							/>
							{errors.country && touched.country && (
								<span className="error">{errors.country}</span>
							)}
						</Form.Group>

						<Form.Group className="mb-5">
							<Input
								type="password"
								placeholder="Password"
								name="password"
								onChange={handleChange}
								value={values.password}
								onBlur={handleBlur}
							/>
							{errors.password && touched.password && (
								<span className="error">{errors.password}</span>
							)}
						</Form.Group>

						<div className="d-flex flex-column">
							{/* <Button className="w-100" variant="green" text="SIGN UP" /> */}

							<Button
								type="submit"
								iconBefore={() =>
									!isSubmitting ? (
										""
									) : (
										<Spinner
											animation="grow"
											css="width:20px;height:20px;"
											variant="white"
										/>
									)
								}
								onClick={handleSubmit}
								className="w-100"
								variant="green"
								text={!isSubmitting ? "SIGN UP" : "Loading..."}
								disabled={isSubmitting}
							/>
						</div>
					</div>
				</form>

				<div className="text-center auth-card-footLink">
					Already have an account? <Link to="/auth/sign-in">Sign in</Link>
				</div>
			</div>
		</div>
	);
};

export default Login;
