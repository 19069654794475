import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import styled from "styled-components/macro";
import {  useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

// Internal dependencies
import Input from "components/Input";
import Button from "components/Button";
import { signInAction } from "redux/actions/authActions";
import authService from "../../services/auth.service";
import "./auth.scss";

const ResetPasword = () => {

	const history = useHistory();
	const dispatch = useDispatch();

	const [error, setError] = useState(null);
	// console.log(state);

	const postFormData = async (v, setSubmitting) => {
		setError(null);
		try {
			const response = await authService.login(v.email, v.password);
			dispatch(signInAction(response.data));
			setSubmitting(false);
			history.push("/dashboard");
		} catch (err) {
			setError(err.response.data.message);
			setSubmitting(false);
		}
	};

	const {
		handleBlur,
		handleChange,
		handleSubmit,
		values,
		isSubmitting,
		setSubmitting,
	} = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		onSubmit: (valueData) => postFormData(valueData, setSubmitting),
		// alert(JSON.stringify(valueData, null, 2));
	});

	return (
		<div className="auth-wrapper">
			<div className="auth-content">
				<form className="card auth-card" onSubmit={handleSubmit}>
					<h3 className="auth-card-title">Reset Password</h3>
					<p className="mb-5 auth-card-subtext">
						Enter the email associated with your account and we’ll send and
						email with instructions to reset your password.
					</p>

					{error && (
						<div className="text-danger text-center">
							<p>*{error}</p>
						</div>
					)}

					<div>
						<Form.Group className="mb-5">
							<Input
								type="text"
								placeholder="Enter your email address"
								name="email"
								onChange={handleChange}
								value={values.email}
								onBlur={handleBlur}
							/>
						</Form.Group>

						<div className="d-flex flex-column">
							<Button
								type="submit"
								iconBefore={() =>
									!isSubmitting ? (
										""
									) : (
										<Spinner
											animation="grow"
											css="width:20px;height:20px;"
											variant="white"
										/>
									)
								}
								onClick={handleSubmit}
								className="w-100"
								variant="green"
								text={!isSubmitting ? "Continue" : "Loading..."}
								disabled={isSubmitting}
							/>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ResetPasword;
