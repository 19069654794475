import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import { Link, useHistory } from "react-router-dom";

// Internal dependencies
import Button from "components/Button";
import LRightIllustration from "components/VectorIcons/LRightIllustration";
import MLRightIllustration from "components/VectorIcons/MLRightIllustration";
import dashboardImg from "assets/images/dashboard.png";
import bannerBg from "assets/images/bG.png";
import partnersImgMobile from "assets/images/partners-mobile.png";
import partnersImgDesktop from "assets/images/partners-desktop.png";
import CirclePath from "components/VectorIcons/CirclePath";

const navLinks = [
	{ name: "About", href: "/#" },
	{ name: "Pricing", href: "/#" },
	{ name: "Contact", href: "/#" },
	{ name: "Documentation", href: "/#" },
];

const purpleBtn = css`
	font-weight: bold;
	font-size: 20px;
	line-height: 30px;
	color: #8c30f5;
`;

const Landing = () => {
	const history = useHistory();

	const [showNav, setShowNav] = useState(false);

	const handleShowNav = () => setShowNav(true);
	const handleHideNav = () => setShowNav(false);

	const renderNavLinks = () =>
		navLinks.map(({ name, href }) => (
			<li>
				<Link className="mx-lg-3 mx-xl-4" to={href}>
					{name}
				</Link>
			</li>
		));

	useEffect(() => {
		document.body.style.backgroundColor = "#fff";

		return () => {
			document.body.style.backgroundColor = "#ECEFF4";
		};
	}, []);

	return (
		<>
			<div css="overflow-x:hidden" className="position-relative">
				<CirclePathContainer className="d-lg-none">
					<CirclePath />
				</CirclePathContainer>

				<Banner className="position-relative">
					<Nav className="d-flex align-items-center">
						<div className="container">
							<div className="d-none d-lg-flex justify-content-between align-items-center">
								<LinkList className="d-flex justify-content-start pl-0 m-0">
									{renderNavLinks()}
								</LinkList>

								<div className="text-center" css="width: 40%">
									<h1 className="m-0 header-text">Collect</h1>
								</div>

								<div className="d-flex justify-content-end" css="width: 30%">
									<Button
										css="border-radius:8px;background-color:#fff;height:40px"
										onClick={() => history.push("/auth/sign-in")}
										className="px-4 mr-3"
										text="Login"
									/>

									<Button
										onClick={() => history.push("/auth/sign-up")}
										css="border-radius:8px;height:40px"
										className="px-4"
										variant="green"
										text="Sign up"
									/>
								</div>
							</div>

							{/* Mobile Nav */}

							<div className="d-flex d-lg-none justify-content-between align-items-center">
								<div css="width:33%" />
								<div className="text-center" css="width: 33%">
									<h1 className="m-0 header-text ">Collect</h1>
								</div>

								<div css="width: 33%" className="text-right">
									<button className="btn" type="button" onClick={handleShowNav}>
										<svg
											width={25}
											height={17}
											viewBox="0 0 25 17"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M0 16.8334H25V14.0556H0V16.8334ZM0 9.88897H25V7.11119H0V9.88897ZM0 0.166748V2.94452H25V0.166748H0Z"
												fill="#323232"
											/>
										</svg>
									</button>
								</div>
							</div>

							<MobileMenuList className={showNav ? "show" : ""}>
								<div className="d-flex justify-content-end">
									<button type="button" className="btn" onClick={handleHideNav}>
										<svg
											width={24}
											height={24}
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z"
												fill="black"
											/>
										</svg>
									</button>
								</div>
								{renderNavLinks()}
							</MobileMenuList>
						</div>
					</Nav>

					<div className="container my-5">
						<section className="row align-items-center mb-5">
							<div className="col-12 col-lg-6 mb-3">
								<BannerSection>
									<Title className="b_title mb-3 mb-lg-4">
										Use for <br /> recurring
										<br /> payments
									</Title>

									<p className="b_info mb-4">
										We make it easy to collect <b>Direct Debit</b> payments from
										your customers, even for one-off or variable amounts.
									</p>

									<Button
										onClick={() => history.push("/auth/sign-up")}
										css="width:100%;@media screen and (min-width:768px){width:unset}"
										variant="green"
										text="Start for free - Get 10 free transactions"
									/>
								</BannerSection>
							</div>

							<div className="col-12 col-lg-6">
								<LRightIllustration className="d-none d-lg-block" />
								<MLRightIllustration className="w-100 d-lg-none" />
							</div>
						</section>
					</div>
				</Banner>

				<Partners className="py-5">
					<div className="t-contain text-center container mb-lg-5">
						<h3 className="title">Built On Open Banking</h3>

						<p className="subtext mb-4">
							We are connected to every bank in Nigeria through our reliable
							service provider. Coming soon to 🇰🇪 Kenya and 🇿🇦 South Africa.
						</p>
					</div>

					<div className="text-center">
						<img
							className="img-fluid d-lg-none"
							src={partnersImgMobile}
							alt="Partners"
						/>
						<img
							className="img-fluid d-none d-lg-block"
							src={partnersImgDesktop}
							alt="Partners"
						/>
					</div>
				</Partners>

				<OffersSection>
					<div className="container-lg">
						<div className="row">
							<div className="px-0 px-lg-4 mb-5 col-12 col-lg-6">
								<OffersCard>
									<h4 className="text-center">Subscription Payments</h4>

									<p>
										Recurring Payments gives you the power to set up your own
										flexible billing schedule and can be accessed via a secure
										payment link on your website, or our online tool.
									</p>
								</OffersCard>
							</div>

							<div className="px-0 px-lg-4 mb-5 col-12 col-lg-6">
								<OffersCard>
									<h4 className="text-center">Invoices</h4>

									<p>
										We make it easy for your customers to pay you. They only
										need to set up their payment details once, securely online.
										Then Collect one-off or recurring payments whenever they are
										due.
									</p>
								</OffersCard>
							</div>

							<div className="px-0 px-lg-4 mb-4 col-12 col-lg-6">
								<OffersCard>
									<h4 className="text-center">No More Bounced Cheques</h4>

									<p>
										Avoid the hassle of cashing cheques, Protrect your business
										from fraud. Easily track payments and get notified when they
										fail. You can re-submit the payment anytime.
									</p>
								</OffersCard>
							</div>

							<div className="mb-4 col-12 col-lg-6">
								<div
									css="@media screen and (min-width: 992px) {margin-top: 100px;}"
									className="d-flex justify-content-center"
								>
									<Button
										onClick={() => history.push("/auth/sign-up")}
										variant="green"
										text="Start for free - Get 10 free transactions"
									/>
								</div>
							</div>
						</div>
					</div>
				</OffersSection>

				<div className="container my-5">
					<section className="mb-lg-5">
						<BillingAutomationSection className="ml-lg-5">
							<div className="d-flex flex-column flex-lg-row align-items-lg-center">
								<div className="mcol-1">
									<h6 className="mb-lg-3 competH">Competitive Pricing</h6>

									<p className="mb-3">
										Enjoy more benefits while paying below industry rates.
									</p>
								</div>

								<div className="d-lg-flex pt-lg-3">
									<span className="d-none d-lg-inline">
										<svg
											width={49}
											height={48}
											viewBox="0 0 49 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M36.4998 40H24.4998C24.1462 40 23.8071 39.8595 23.557 39.6095C23.307 39.3594 23.1665 39.0203 23.1665 38.6667V36C23.1665 35.6464 23.307 35.3073 23.557 35.0572C23.8071 34.8072 24.1462 34.6667 24.4998 34.6667H36.4998C36.8535 34.6667 37.1926 34.8072 37.4426 35.0572C37.6927 35.3073 37.8332 35.6464 37.8332 36V38.6667C37.8332 39.0203 37.6927 39.3594 37.4426 39.6095C37.1926 39.8595 36.8535 40 36.4998 40Z"
												fill="#D6B1FF"
											/>
											<path
												d="M36.4998 8H12.4998C12.1462 8 11.8071 8.14047 11.557 8.39052C11.307 8.64057 11.1665 8.97971 11.1665 9.33333V12C11.1665 12.3536 11.307 12.6928 11.557 12.9428C11.8071 13.1929 12.1462 13.3333 12.4998 13.3333H36.4998C36.8535 13.3333 37.1926 13.1929 37.4426 12.9428C37.6927 12.6928 37.8332 12.3536 37.8332 12V9.33333C37.8332 8.97971 37.6927 8.64057 37.4426 8.39052C37.1926 8.14047 36.8535 8 36.4998 8Z"
												fill="#2EC5CE"
											/>
											<path
												d="M36.4998 16H12.4998C12.1462 16 11.8071 16.1405 11.557 16.3905C11.307 16.6406 11.1665 16.9797 11.1665 17.3333V30.6667C11.1665 31.0203 11.307 31.3594 11.557 31.6095C11.8071 31.8595 12.1462 32 12.4998 32H36.4998C36.8535 32 37.1926 31.8595 37.4426 31.6095C37.6927 31.3594 37.8332 31.0203 37.8332 30.6667V17.3333C37.8332 16.9797 37.6927 16.6406 37.4426 16.3905C37.1926 16.1405 36.8535 16 36.4998 16Z"
												fill="#8C30F5"
											/>
										</svg>
									</span>

									<div>
										<p className="mb-2 percentage">1%</p>

										<p className="mb-4 mb-lg-3 grey-text">
											Fee is capped at N500 per transaction
										</p>
									</div>
								</div>
							</div>
						</BillingAutomationSection>
					</section>

					<section className="row mb-5">
						<div className="d-none d-lg-block col-12 col-lg-6 mb-3">
							<div className="mr-lg-5 mt-5">
								<img className="img-fluid" src={dashboardImg} alt="dashboard" />
							</div>
						</div>

						<div className="col-12 col-lg-6 mb-3 mt-3">
							<BillingAutomationSection className="ml-lg-5">
								<h6 className="mb-lg-3">
									Billing automation is the future of smart businesses
								</h6>

								<p className="mb-3 mb-lg-4">
									Getting paid later is not free, it impacts your working
									capital. Automate your cash collection process by with
									Collect.
								</p>

								<button
									onClick={() => history.push("/auth/sign-up")}
									css={purpleBtn}
									type="button"
									className="btn d-none d-lg-block"
								>
									Get started{" "}
									<svg
										width={24}
										height={24}
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M15.2931 6.70711C14.9026 6.31658 14.9026 5.68342 15.2931 5.29289C15.6837 4.90237 16.3168 4.90237 16.7074 5.29289L22.7073 11.2929C23.0979 11.6834 23.0979 12.3166 22.7073 12.7071L16.7074 18.7071C16.3168 19.0976 15.6837 19.0976 15.2931 18.7071C14.9026 18.3166 14.9026 17.6834 15.2931 17.2929L19.586 13H2.01103C1.45265 13 1 12.5523 1 12C1 11.4477 1.45265 11 2.01103 11H19.586L15.2931 6.70711Z"
											fill="#8C30F5"
										/>
									</svg>
								</button>
							</BillingAutomationSection>
						</div>
					</section>
				</div>
			</div>

			<Footer>
				<div className="container">
					<h4 className="title">Collect</h4>

					<ul className="d-flex flex-column flex-md-row list-unstyled mb-1">
						<li className="mr-3">
							<Link to="/#" className="footLink">
								Documentation
							</Link>
						</li>
						<li>
							<Link to="/#" className="footLink">
								API
							</Link>
						</li>
					</ul>

					<ul className="d-flex flex-column flex-md-row list-unstyled">
						<li className="mr-3">
							<Link to="/#" className="footLink">
								About
							</Link>
						</li>
						<li className="mr-3">
							<Link to="/#" className="footLink">
								Features
							</Link>
						</li>
						<li className="mr-3">
							<Link to="/#" className="footLink">
								Pricing
							</Link>
						</li>
						<li className="mr-3">
							<Link to="/#" className="footLink">
								Careers
							</Link>
						</li>
						<li className="mr-3">
							<Link to="/#" className="footLink">
								Help
							</Link>
						</li>
						<li className="mr-3">
							<Link to="/#" className="footLink">
								Privacy Policy
							</Link>
						</li>
					</ul>
					<p className="rights">
						© {new Date().getFullYear()} Collect Africa. All rights reserved
					</p>
				</div>
			</Footer>
		</>
	);
};

const Nav = styled.nav`
	height: 72px;
	/* box-shadow: 0px 1px 0px #e5e9f2; */

	.header-text {
		font-size: 20px;
		line-height: 23px;
		letter-spacing: -1px;

		@media screen and (min-width: 992px) {
			font-size: 30px;
			line-height: 68px;
		}
	}
`;

const Banner = styled.div`
	@media screen and (min-width: 992px) {
		background-image: url(${bannerBg});
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		backdrop-filter: blur(100px);
		height: 100vh;
	}
`;

const CirclePathContainer = styled.figure`
	position: absolute;
	left: 120%;
	top: 14%;
	transform: translate(-50%, -50%);
	z-index: -1;
	pointer-events: none;

	@media screen and (min-width: 400px) {
		left: 105%;
	}
	@media screen and (min-width: 768px) {
		left: 64%;
	}
`;

const MobileMenuList = styled.ul`
	padding: 32px 38px 72px;
	position: absolute;
	z-index: 10000;
	background-color: var(--white);
	width: 100%;
	left: 0;
	right: 0;
	list-style-type: none;
	font-family: Manrope;
	top: 0;
	pointer-events: none;
	transform: translateY(-500px);
	transition: all 0.5s ease-in;

	&.show {
		pointer-events: all;
		transform: translateY(0);
	}

	li {
		a {
			font-size: 20px;
			line-height: 23px;
			letter-spacing: -0.5px;
			color: #000000;
			display: block;
			margin-bottom: 20px;
		}
	}
`;

const LinkList = styled.ul`
	list-style-type: none;
	width: 30%;

	li {
		a {
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			letter-spacing: -0.5px;
			font-feature-settings: "liga" off;
			color: #18191f;
			font-family: Manrope;
			text-decoration: none;
			transition: 0.3s ease;

			&:hover {
				color: var(--c-green);
			}
		}
	}
`;

const BannerSection = styled.div`
	.b_title {
		font-family: Manrope;

		@media screen and (min-width: 992px) {
			margin-bottom: 40px !important;
		}
	}

	.b_info {
		font-size: 18px;
		line-height: 25px;
		letter-spacing: -0.5px;
		font-family: Manrope;

		@media screen and (min-width: 992px) {
			font-size: 30px;
			line-height: 32px;
			margin-bottom: 40px;
		}
	}
`;

const Title = styled.h1`
	font-weight: 700;
	font-size: 36px;
	line-height: 49px;
	letter-spacing: -2px;

	@media screen and (min-width: 992px) {
		font-size: 72px;
		line-height: 68px;
	}
`;

const BillingAutomationSection = styled.div`
	position: relative;
	margin-top: 40px;
	font-family: Manrope;

	h6 {
		font-size: 20px;
		line-height: 30px;
		font-weight: 700;

		&.competH {
			@media screen and (min-width: 992px) {
				font-weight: 800;
				font-size: 48px;
				line-height: 64px;
			}
		}
	}

	.percentage {
		font-weight: 700;
		font-size: 30px;
		line-height: 30px;
		letter-spacing: -0.5px;
		color: #010933;
	}

	.grey-text {
		font-size: 14px;
		line-height: 30px;
		letter-spacing: -0.5px;
		color: #c4c4c4;

		@media screen and (min-width: 992px) {
			font-size: 16px;
			color: #18191f;
		}
	}

	.mcol-1 {
		@media screen and (min-width: 992px) {
			width: 50%;
		}
	}

	&::after {
		position: absolute;
		content: "";
		height: 7px;
		width: 109px;
		background-color: #3ab75d;
		top: -20px;
		left: 0;

		@media screen and (min-width: 992px) {
			content: none;
		}
	}
`;

const Partners = styled.section`
	color: #18191f;

	.t-contain {
		h3.title {
			font-weight: 700;
			font-size: 25px;
			line-height: 64px;

			@media screen and (min-width: 992px) {
				font-size: 48px;
				line-height: 64px;
			}
		}

		p.subtext {
			font-size: 18px;
			line-height: 32px;
		}

		@media screen and (min-width: 992px) {
			width: 564px;
		}
	}
`;

const OffersSection = styled.section`
	@media screen and (min-width: 992px) {
		background: #c1d9ff;
		padding-top: 90px;
		padding-bottom: 150px;
	}
`;

const OffersCard = styled.div`
	background: #f3f4f5;
	border-radius: 3px;
	padding: 12px 28px 29px;
	min-height: 260px;

	@media screen and (min-width: 992px) {
		padding: 58px;
	}

	h4 {
		font-weight: 700;
		font-size: 25px;
		line-height: 32px;
		color: #2c2d2f;
		margin-bottom: 15px;

		@media screen and (min-width: 992px) {
			font-size: 30px;
			line-height: 32px;
		}
	}

	p {
		font-size: 14px;
		line-height: 32px;
		color: #505255;

		@media screen and (min-width: 992px) {
			font-size: 18px;
			line-height: 32px;
		}
	}
`;

const Footer = styled.footer`
	background-color: #0b0d17;
	padding-top: 40px;
	padding-bottom: 56px;

	h4.title {
		font-size: 30px;
		line-height: 68px;
		letter-spacing: -1px;
		color: var(--white);
	}

	.footLink {
		font-size: 16px;
		line-height: 26px;
		color: var(--white);
	}

	.rights {
		font-size: 14px;
		line-height: 26px;
		color: #d9dbe1;
	}
`;

export default Landing;
